"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserDataOnSessionStorage = exports.setUserInAnalytics = exports.setPermInLocalStorage = exports.fetchUserData = exports.routeToHomeActivity = exports.checkOktaSession = exports.handleIdV2TokenRefresh = exports.setSessionId = exports.renewToken = exports.fetchAnalyticsAuthAndCharts = exports.fetchAndSetSisenseJWT = exports.client = exports.USER_LABEL_PERMISSIONS_KEY = exports.USER_PERMISSIONS_KEY = void 0;
var okta_auth_js_1 = require("@okta/okta-auth-js");
var unleash_proxy_client_1 = require("unleash-proxy-client");
var single_spa_1 = require("single-spa");
var mfa_communication_1 = require("@copilot/mfa-communication");
var api_1 = require("./api");
var constants_1 = require("src/utils/constants");
var helper_1 = require("../utils/helper");
exports.USER_PERMISSIONS_KEY = 'userperm';
exports.USER_LABEL_PERMISSIONS_KEY = 'userlabels';
var oktaConfig = {
    issuer: process.env.OKTA_ISSUER,
    clientId: process.env.OKTA_CLIENT_ID,
    redirectUri: "".concat(window.location.origin, "/auth/v2/login"),
    pkce: true,
    responseType: ['token', 'id_token', 'code'],
    scopes: ['openid', 'email', 'profile', 'address', 'phone', 'offline_access'],
};
var config = {
    url: process.env.UNLEASH_PROXY_URL,
    clientKey: process.env.UNLEASH_CLIENT_KEY,
    appName: 'copilot-authentication',
    disableRefresh: true,
};
exports.client = new unleash_proxy_client_1.UnleashClient(config);
function fetchAndSetSisenseJWT(accessToken) {
    return __awaiter(this, void 0, void 0, function () {
        var sisenseJWT;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!accessToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, (mfa_communication_1.AAMLController === null || mfa_communication_1.AAMLController === void 0 ? void 0 : mfa_communication_1.AAMLController.fetchJWT(accessToken))];
                case 1:
                    sisenseJWT = _a.sent();
                    sessionStorage.setItem('sisenseJWT', JSON.stringify(sisenseJWT));
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
exports.fetchAndSetSisenseJWT = fetchAndSetSisenseJWT;
function fetchAnalyticsAuthAndCharts(accessToken) {
    return __awaiter(this, void 0, void 0, function () {
        var isAnalyticsEnabled, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isAnalyticsEnabled = exports.client.isEnabled(constants_1.UNLEASH_FEATURE_FLAGS.FEATURE_ANALYTICS_DASHBOARD);
                    if (!(isAnalyticsEnabled && accessToken)) return [3 /*break*/, 2];
                    return [4 /*yield*/, (mfa_communication_1.AnalyticsController === null || mfa_communication_1.AnalyticsController === void 0 ? void 0 : mfa_communication_1.AnalyticsController.getAuthAndCharts(accessToken))];
                case 1:
                    data = _a.sent();
                    if (data === null || data === void 0 ? void 0 : data.token) {
                        sessionStorage.setItem('reportingJWT', data.token);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
exports.fetchAnalyticsAuthAndCharts = fetchAnalyticsAuthAndCharts;
var renewToken = function (authClient, key) { return __awaiter(void 0, void 0, void 0, function () {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, authClient.tokenManager.renew(key)];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                e_1 = _a.sent();
                console.error('Failed to renew expired token', e_1);
                (0, single_spa_1.navigateToUrl)('/auth/v2/login');
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.renewToken = renewToken;
var setSessionId = function (authClient) { return __awaiter(void 0, void 0, void 0, function () {
    var res, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, authClient.session.get()];
            case 1:
                res = _a.sent();
                sessionStorage.setItem('sessionId', res.id);
                return [3 /*break*/, 3];
            case 2:
                e_2 = _a.sent();
                console.error(e_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.setSessionId = setSessionId;
function sessionLifecycle(datadogRum) {
    return __awaiter(this, void 0, void 0, function () {
        var authClient, redirectDisabledRoute;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!oktaConfig.issuer || !oktaConfig.clientId) {
                        return [2 /*return*/];
                    }
                    authClient = new okta_auth_js_1.OktaAuth(oktaConfig);
                    // Triggered when a token has expired
                    authClient.tokenManager.on('expired', function (key, expiredToken) {
                        return __awaiter(this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                (0, exports.renewToken)(authClient, key);
                                return [2 /*return*/];
                            });
                        });
                    });
                    // Triggered when a token has been renewed
                    authClient.tokenManager.on('renewed', function (key, newToken, oldToken) {
                        if (key === 'accessToken') {
                            var accessToken = newToken;
                            sessionStorage.setItem('accessToken', accessToken.accessToken);
                            fetchAndSetSisenseJWT(accessToken.accessToken);
                            fetchAnalyticsAuthAndCharts(accessToken.accessToken);
                        }
                        if (key === 'refreshToken') {
                            var refreshToken = newToken;
                            if (refreshToken === null || refreshToken === void 0 ? void 0 : refreshToken.refreshToken) {
                                sessionStorage.setItem('refreshToken', refreshToken.refreshToken);
                            }
                        }
                    });
                    // Triggered when an OAuthError is returned via the API (typically during token renew)
                    authClient.tokenManager.on('error', function (err) {
                        console.error('TokenManager error:', err);
                        (0, single_spa_1.navigateToUrl)('/auth/v2/login');
                    });
                    // adding redirect listener here
                    (0, helper_1.validateAndSetForceRedirect)();
                    window.addEventListener('onV1Login', function (event) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            fetchAndSetSisenseJWT(event.detail.accessToken);
                            return [2 /*return*/];
                        });
                    }); });
                    window.document.addEventListener('visibilitychange', function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (document.visibilityState === 'visible') {
                                // checks and updates(if required) accessToken when user returns to the page
                                (0, exports.handleIdV2TokenRefresh)(authClient);
                            }
                            return [2 /*return*/];
                        });
                    }); });
                    (0, helper_1.default)();
                    window.addEventListener('onLoginSuccess', function (event) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b, _c;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    authClient.tokenManager.start();
                                    authClient.tokenManager.setTokens(event.detail.tokens);
                                    sessionStorage.setItem('accessToken', event.detail.tokens.accessToken.accessToken);
                                    if ((_c = (_b = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.tokens) === null || _b === void 0 ? void 0 : _b.refreshToken) === null || _c === void 0 ? void 0 : _c.refreshToken) {
                                        sessionStorage.setItem('refreshToken', event.detail.tokens.refreshToken.refreshToken);
                                    }
                                    return [4 /*yield*/, (0, exports.fetchUserData)()];
                                case 1:
                                    _d.sent();
                                    fetchAndSetSisenseJWT(event.detail.tokens.accessToken.accessToken);
                                    fetchAnalyticsAuthAndCharts(event.detail.tokens.accessToken.accessToken);
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    return [4 /*yield*/, (0, exports.setSessionId)(authClient)];
                case 1:
                    _a.sent();
                    redirectDisabledRoute = ['/auth/login', '/onboarding/signup'];
                    if (window.location.pathname === '/auth/v2/login') {
                        datadogRum.setUser({ isStaffUserFederated: true }); // set user as federate in datadog
                        (0, exports.checkOktaSession)(authClient);
                    }
                    else if (localStorage.getItem('isLoggedInWithIdV2') &&
                        !redirectDisabledRoute.some(function (value) {
                            return window.location.pathname.includes(value);
                        })) {
                        if (!sessionStorage.getItem('userId')) {
                            (0, single_spa_1.navigateToUrl)('/auth/v2/login');
                            return [2 /*return*/];
                        }
                        datadogRum.setUser({ isStaffUserFederated: true }); // set user as federate in datadog
                        authClient.start();
                    }
                    else {
                        datadogRum.setUser({ isStaffUserFederated: false }); // set user as non federate in datadog
                    }
                    if (sessionStorage.getItem('accessToken')) {
                        // check if there is redirect url available.
                        (0, helper_1.redirectAndRemoveLink)();
                    }
                    else {
                        return [2 /*return*/];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
exports.default = sessionLifecycle;
var handleIdV2TokenRefresh = function (authClient) {
    authClient.tokenManager
        .get('accessToken')
        .then(function (token) {
        if (token && authClient.tokenManager.hasExpired(token)) {
            // Token has been expired due to timeout
            (0, exports.renewToken)(authClient, 'accessToken');
        }
    })
        .catch(function (err) {
        // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
        console.error(err);
    });
};
exports.handleIdV2TokenRefresh = handleIdV2TokenRefresh;
var checkOktaSession = function (authClient) { return __awaiter(void 0, void 0, void 0, function () {
    var sessionExists, fromLogin, tokensResponse, error_1;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, authClient.session.exists()];
            case 1:
                sessionExists = _c.sent();
                fromLogin = new URLSearchParams(window.location.search).has('fromLogin');
                if (!sessionExists) return [3 /*break*/, 8];
                window.dispatchEvent(new CustomEvent('sessionLoading', {
                    detail: true,
                }));
                return [4 /*yield*/, authClient.tokenManager.start()];
            case 2:
                _c.sent();
                tokensResponse = void 0;
                _c.label = 3;
            case 3:
                _c.trys.push([3, 5, , 6]);
                return [4 /*yield*/, authClient.token.getWithoutPrompt()];
            case 4:
                tokensResponse = _c.sent();
                return [3 /*break*/, 6];
            case 5:
                error_1 = _c.sent();
                console.error('catch getWithoutPrompt', error_1);
                window.dispatchEvent(new CustomEvent('onLoginFailure', { detail: error_1.message }));
                return [2 /*return*/];
            case 6:
                authClient.tokenManager.setTokens(tokensResponse.tokens);
                sessionStorage.setItem('accessToken', tokensResponse.tokens.accessToken.accessToken);
                if ((_b = (_a = tokensResponse === null || tokensResponse === void 0 ? void 0 : tokensResponse.tokens) === null || _a === void 0 ? void 0 : _a.refreshToken) === null || _b === void 0 ? void 0 : _b.refreshToken) {
                    sessionStorage.setItem('refreshToken', tokensResponse.tokens.refreshToken.refreshToken);
                }
                return [4 /*yield*/, (0, exports.fetchUserData)()];
            case 7:
                _c.sent();
                fetchAndSetSisenseJWT(tokensResponse.tokens.accessToken.accessToken);
                fetchAnalyticsAuthAndCharts(tokensResponse.tokens.accessToken.accessToken);
                return [3 /*break*/, 9];
            case 8:
                if (fromLogin) {
                    console.error('expected session to exist for login redirect');
                    window.dispatchEvent(new CustomEvent('onLoginFailure', {
                        detail: 'Expected session from login redirect, but it does not exist. Please try logging in again.',
                    }));
                    return [2 /*return*/];
                }
                _c.label = 9;
            case 9: return [2 /*return*/];
        }
    });
}); };
exports.checkOktaSession = checkOktaSession;
var routeToHomeActivity = function (isNewRouteEnabled) {
    // check if there is a route to redirect available here, If yes, push to that route and ignore
    var redirectUrl = localStorage.getItem('redirect_uri');
    if ((redirectUrl === null || redirectUrl === void 0 ? void 0 : redirectUrl.length) > 0) {
        (0, helper_1.redirectAndRemoveLink)();
    }
    else if (isNewRouteEnabled) {
        (0, single_spa_1.navigateToUrl)('/home/activity');
    }
    else {
        (0, single_spa_1.navigateToUrl)('/admin/activity');
    }
};
exports.routeToHomeActivity = routeToHomeActivity;
var fetchUserData = function () { return __awaiter(void 0, void 0, void 0, function () {
    var data, apiData, err_1, accounts, userData, accountId, isSelfOnboardingEnabled, isNewRouteEnabled, isConsentFormEnabled, e_3;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _f.trys.push([0, 2, , 3]);
                return [4 /*yield*/, api_1.default.identity.get('/users/self')];
            case 1:
                apiData = (_f.sent()).data;
                data = apiData;
                return [3 /*break*/, 3];
            case 2:
                err_1 = _f.sent();
                window.dispatchEvent(new CustomEvent('onLoginFailure', { detail: err_1.message }));
                return [2 /*return*/];
            case 3:
                accounts = Object.values((_a = data.accounts) !== null && _a !== void 0 ? _a : {});
                userData = __assign(__assign({}, data), { accounts: accounts });
                window.sessionStorage.setItem('user', data.id);
                window.sessionStorage.setItem('userId', data.id);
                window.sessionStorage.setItem('firstName', data.firstName);
                window.sessionStorage.setItem('lastName', data.lastName);
                window.sessionStorage.setItem('email', data.email);
                window.sessionStorage.setItem('logoutRedirectUrl', (_b = data.logoutRedirectUrl) !== null && _b !== void 0 ? _b : 'null');
                localStorage.setItem('isLoggedInWithIdV2', 'true');
                if (!accounts.length) {
                    sessionStorage.removeItem('accounts');
                    (0, exports.setUserInAnalytics)({
                        email: data.email,
                        userId: data.id,
                        accountId: '',
                        accountName: '',
                    });
                    (0, single_spa_1.navigateToUrl)('/auth/no-account');
                    return [2 /*return*/];
                }
                setUserDataOnSessionStorage(userData);
                accountId = sessionStorage.getItem(constants_1.SESSION_STORAGE_KEYS.ACCOUNT_ID);
                _f.label = 4;
            case 4:
                _f.trys.push([4, 6, , 7]);
                exports.client.updateContext({
                    userId: accountId,
                    properties: { domainName: window.location.hostname },
                });
                return [4 /*yield*/, exports.client.start()];
            case 5:
                _f.sent();
                isSelfOnboardingEnabled = exports.client.isEnabled(constants_1.UNLEASH_FEATURE_FLAGS.FEATURE_SELF_ONBOARDING);
                isNewRouteEnabled = exports.client.isEnabled(constants_1.UNLEASH_FEATURE_FLAGS.FEATURE_L1_URL_CHANGES);
                isConsentFormEnabled = exports.client.isEnabled(constants_1.UNLEASH_FEATURE_FLAGS.FEATURE_CONSENT_FORM);
                if (isSelfOnboardingEnabled) {
                    window.dispatchEvent(new CustomEvent('fetchSelfOnboardingData', {
                        detail: {
                            accountId: accountId,
                            eventFromHeader: false,
                            isNewRouteEnabled: isNewRouteEnabled,
                            isConsentFormEnabled: isConsentFormEnabled,
                            consented: (_c = data === null || data === void 0 ? void 0 : data.tncConsent) === null || _c === void 0 ? void 0 : _c.consented,
                        },
                    }));
                }
                else {
                    if (!isConsentFormEnabled) {
                        (0, exports.routeToHomeActivity)(isNewRouteEnabled);
                    }
                    if (isConsentFormEnabled) {
                        if ((_d = data === null || data === void 0 ? void 0 : data.tncConsent) === null || _d === void 0 ? void 0 : _d.consented) {
                            (0, exports.routeToHomeActivity)(isNewRouteEnabled);
                        }
                        else {
                            (0, single_spa_1.navigateToUrl)('/auth/terms');
                        }
                    }
                }
                return [3 /*break*/, 7];
            case 6:
                e_3 = _f.sent();
                if ((_e = data === null || data === void 0 ? void 0 : data.tncConsent) === null || _e === void 0 ? void 0 : _e.consented) {
                    (0, single_spa_1.navigateToUrl)('/home/activity');
                }
                else {
                    (0, single_spa_1.navigateToUrl)('/auth/terms');
                }
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); };
exports.fetchUserData = fetchUserData;
var setPermInLocalStorage = function (data) {
    // set the current user permission into the localstorage
    window.localStorage.setItem(exports.USER_PERMISSIONS_KEY, JSON.stringify(data.permissions || []));
};
exports.setPermInLocalStorage = setPermInLocalStorage;
var setUserInAnalytics = function (user) {
    var _a, _b;
    (_a = window === null || window === void 0 ? void 0 : window.FS) === null || _a === void 0 ? void 0 : _a.identify(user.userId, {
        email: user.email,
        isLoggedInWithIdV2: true,
        accountId: user.accountId,
        accountName: user.accountName,
    });
    (_b = window === null || window === void 0 ? void 0 : window.DD_RUM) === null || _b === void 0 ? void 0 : _b.setUser({
        email: user.email,
        isLoggedInWithIdV2: true,
        accountId: user.accountId,
        accountName: user.accountName,
        isStaffUserFederated: true,
        userId: user.userId,
    });
};
exports.setUserInAnalytics = setUserInAnalytics;
function setUserDataOnSessionStorage(data) {
    var _a;
    window.sessionStorage.setItem('accounts', JSON.stringify(data.accounts));
    window.sessionStorage.setItem('isStaffUserFederated', (_a = data.isStaffUserFederated) !== null && _a !== void 0 ? _a : 'false');
    window.sessionStorage.setItem('pimVersion', 'pim2');
    var lastSelectedAccountId = window.localStorage.getItem('lastSelectedAccountId');
    if (lastSelectedAccountId) {
        var lastSelectedAccount = data.accounts.find(function (account) { return account.id === lastSelectedAccountId; });
        if (lastSelectedAccount) {
            window.sessionStorage.setItem('accountId', lastSelectedAccount.id);
            window.sessionStorage.setItem('accountName', lastSelectedAccount.name);
            (0, exports.setPermInLocalStorage)(lastSelectedAccount);
            (0, exports.setUserInAnalytics)({
                email: data.email,
                accountId: lastSelectedAccount.id,
                accountName: lastSelectedAccount.name,
                userId: data.id,
            });
            return;
        }
    }
    window.sessionStorage.setItem('accountName', data.accounts[0].name);
    window.sessionStorage.setItem('accountId', data.accounts[0].id);
    window.localStorage.setItem('lastSelectedAccountId', data.accounts[0].id);
    (0, exports.setPermInLocalStorage)(data.accounts[0]);
    (0, exports.setUserInAnalytics)({
        accountName: data.accounts[0].name,
        accountId: data.accounts[0].id,
        email: data.email,
        userId: data.id,
    });
}
exports.setUserDataOnSessionStorage = setUserDataOnSessionStorage;
